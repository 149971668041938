import PropertySearchFilter from '../property/filters';
import Logger from '../logging/logger';

import newValidator from '../validation';
import hasUserProvidedEmail from '../user/has-provided-email';
import { parseBool } from '../strconv/boolean';
import { isVisible } from '../dom/visibility';
import { getUser } from '../user/user';
import { getCurrentTimestamp } from '../time/time';
import { closeExpandedDropdowns } from '../dropdown';
import { createCookie, readCookie } from '../cookies';
import { getUtilityPopupContainer } from '../popups/utility-popups';
import { closeAccountMenu, closeAllUtilityPopups, closeSideNav, openUtilityPopup } from '../popups/utils';
import { isConsentBannerVisible, isContentPreferencesVisible } from '../onetrust';
import { getSessionStartTime } from '../session/time';
import triggerEvent, { EventNameMailshotSubscribe, GA4EventParameters } from '../analytics/events';
import { getLanguage } from '../user/device-info';
import { setupEmailSuggestionFromResult } from '../validation/email-suggest';
import { getEmailAlertFrequency } from '../property/alerts';
import { EmailTypeFeaturedProperties, EmailTypePropertyAlert } from '../user/event-unsubscribed';

export const HistoryCookieName: string = '_twc_popup_history';

const HistoryCookieLifetime: number = 7; // days

const PostTriggerBlockingTime: number = 300; // 5 minutes in seconds

const InitialBlockingTime: number = 60; // 1 minute in seconds

export const TriggerDirectViaURL: string = 'direct-via-url';

export const TriggerDirectViaButton: string = 'direct-via-button';

export const TriggerExitIntent: string = 'exit-intent';

export const TriggerSaveProperty: string = 'save-property';

export const TriggerSaveSearch: string = 'save-search';

export const TriggerInactivity: string = 'inactivity';

const triggerBlockGroups: Array<number> = [
    300, // 5 minutes in seconds
    86400, // 24 hours in seconds
];

const validTriggerIDs: Array<string> = [
    TriggerDirectViaURL,
    TriggerDirectViaButton,
    TriggerExitIntent,
    TriggerSaveProperty,
    TriggerSaveSearch,
    TriggerInactivity,
];

const triggersToNotStoreInHistory: Array<string> = [
    TriggerDirectViaURL,
    TriggerDirectViaButton,
];

const triggersBlockedByInitialBlockingTime: Array<string> = [
    TriggerExitIntent,
];

const triggersAllowedToBypassPostTriggerBlocking: Array<string> = [
    TriggerExitIntent,
];

type AlertPopupHistory = {
    entries: Array<HistoryEntry>;
}

type HistoryEntry = {
    id: string;
    time: number;
}

interface RequestPayload {
    eventID: string;
    forcePopup: boolean;
    filter?: string;
}

interface AlertResponse {
    ignore: boolean;
    html: string;
}

interface FormResponse {
    html: string;
    error: string;
    eventParameters?: GA4EventParameters;
    didyoumean?: string;
    didyoumeanhtml?: string;
}

interface AlertShowResult {
    show: boolean;
    reason?: string;
    info?: Record<string, any>;
}

interface EventValidationResult {
    valid: boolean;
    reason?: string;
}

export interface OpenAlertEvent {
    id: string;
    source?: string;
    parameters?: Record<string, any>;
    onPopupClosedCallback?: () => void;
    onPopupBlockedCallback?: ( result: AlertShowResult ) => void;
}

function getActionNameForEvent( event: OpenAlertEvent ): string {
    const historyEntries = getHistory().entries.filter( ( entry ) => entry.id === event.id );

    let countSuffix = historyEntries.length;

    if ( countSuffix > 0 ) {
        countSuffix = countSuffix - 1;
    }

    switch ( event.id ) {
    case TriggerDirectViaButton: {
        return 'property-email-popup-direct-via-button';
    }
    case TriggerDirectViaURL: {
        return 'property-email-popup-direct-via-url';
    }
    case TriggerSaveProperty: {
        return `property-email-popup-save-property-${countSuffix}`;
    }
    case TriggerSaveSearch: {
        return `property-email-popup-save-search-${countSuffix}`;
    }
    case TriggerExitIntent: {
        return `property-email-popup-exit-intent-${countSuffix}`;
    }
    case TriggerInactivity: {
        return `property-email-popup-inactivity-${countSuffix}`;
    }
    default:
        throw new Error( `unknown event id "${event.id}"` );
    }
}

function createHistoryEntry( openEvent: OpenAlertEvent ): HistoryEntry {
    return {
        id: openEvent.id,
        time: getCurrentTimestamp(),
    };
}

function getHistory(): AlertPopupHistory {
    const cookieValue: string = readCookie( HistoryCookieName );

    if ( cookieValue ) {
        return JSON.parse( cookieValue ) as AlertPopupHistory;
    }

    return updateHistory( {
        entries: [],
    } );
}

function pushHistory( entry: HistoryEntry ): AlertPopupHistory {
    const history: AlertPopupHistory = getHistory();

    history.entries.push( entry );

    return updateHistory( history );
}

function updateHistory( history: AlertPopupHistory ): AlertPopupHistory {
    history.entries = history.entries.sort( ( a: HistoryEntry, b: HistoryEntry ) => a.time - b.time );

    createCookie( HistoryCookieName, JSON.stringify( history ), HistoryCookieLifetime );

    return history;
}

function validateEvent( event: OpenAlertEvent ): EventValidationResult {
    if ( !validTriggerIDs.includes( event.id ) ) {
        return {
            valid: false,
            reason: 'unknown event id',
        };
    }
    return {
        valid: true,
    };
}

function getInitialBlockingTime(): number {
    if ( typeof window.initialBlockingTime !== 'undefined' ) {
        return window.initialBlockingTime;
    }
    return InitialBlockingTime;
}

function getPostTriggerBlockingTime(): number {
    if ( typeof window.postTriggerBlockingTime !== 'undefined' ) {
        return window.postTriggerBlockingTime;
    }
    return PostTriggerBlockingTime;
}

function getTriggerBlockGroups(): Array<number> {
    if ( window.triggerBlockGroups ) {
        return window.triggerBlockGroups;
    }
    return triggerBlockGroups;
}

class AlertPopupManager {
    _alertableElement: HTMLElement | null;

    constructor() {
        Logger.info( 'Initialising alert popup manager', getHistory() );

        this._alertableElement = document.querySelector<HTMLElement>( '[data-alertable]' );

        const currentURL = new URL( window.location.href );

        if ( currentURL.searchParams.get( 'alert' ) === 'true' ) {
            const propertyAlert = document.querySelector( '.property-alert' ) as HTMLElement;

            const event: OpenAlertEvent = {
                id: TriggerDirectViaURL,
                source: window.location.href,
            };

            if ( propertyAlert ) {
                this._setupAlert( propertyAlert, event );
            }

            if ( propertyAlert && propertyAlert.querySelector( 'form' ) ) {
                this._setupAlertForm( propertyAlert, event );
            }
        }

        this._removeQueryParam();

        window.addEventListener( 'twc:popup:open-property-alert', this._handleOpenEvent.bind( this ) );

        window.addEventListener( 'twc:exit-intent', this._handleExitIntent.bind( this ) );

        document.querySelectorAll<HTMLButtonElement>( '[data-action="open-alert-popup"]' ).forEach( ( btn: HTMLButtonElement ) => {
            btn.addEventListener( 'click', () => {
                this._open( {
                    id: TriggerDirectViaButton,
                    source: btn.dataset.actionSource,
                }, true );
            } );
        } );
    }

    /**
     * _handleOpenEvent handles all open alert request events
     * @param {CustomEvent} event - the event triggered
     * @returns {Promise<void>}
     */
    async _handleOpenEvent( event: CustomEvent<OpenAlertEvent> ): Promise<void> {
        this._open( event.detail );
    }

    /**
     * _handleExitIntent handles all exit intent events
     * @param {CustomEvent} event - the event triggered
     * @returns {Promise<void>}
     */
    async _handleExitIntent( event: CustomEvent ): Promise<void> {
        await this._open( {
            id: TriggerExitIntent,
        } );
    }

    /**
     * _open handles setting up and showing the alert popup
     * @param {OpenAlertEvent} event
     * @returns {Promise<void>}
     */
    async _open( event: OpenAlertEvent, force = false ): Promise<void> {
        const validationResult = validateEvent( event );

        if ( !validationResult.valid ) {
            Logger.info( `[app] Disallowing property alert popup as validation failed with reason: ${validationResult.reason}`, {
                event: event,
                force: force,
            } );
            return;
        }

        if ( !force ) {
            const result: AlertShowResult = await this._canBeShown( event );

            if ( !result.show ) {
                Logger.info( `[app] Disallowing property alert popup with reason: ${result.reason}`, {
                    event: event,
                    force: force,
                    info: result.info,
                } );

                if ( typeof event.onPopupBlockedCallback === 'function' ) {
                    event.onPopupBlockedCallback( result );
                }
                return;
            }
        }

        Logger.info( 'Allowing property alert popup', {
            force: force,
            event: event,
        } );

        closeAllUtilityPopups( false );

        closeExpandedDropdowns();

        closeAccountMenu();

        await closeSideNav();

        const payload: RequestPayload = {
            eventID: event.id,
            forcePopup: force,
        };

        if ( this._alertableElement && this._alertableElement.dataset.saveValue ) {
            payload.filter = JSON.parse( this._alertableElement.dataset.saveValue );
        }

        const url = new URL( window.location.origin + window.alertableURL );
        url.searchParams.set( 'eventID', event.id );

        const response: Response = await window.http( url, {
            method: 'POST',
            body: JSON.stringify( payload ),
            headers: {
                'Content-Type': 'application/json',
            },
        } );

        if ( !response.ok ) {
            throw new Error( 'alert response failed with status ' + response.status );
        }

        const body = await response.json() as AlertResponse;

        if ( body.ignore ) {
            return;
        }

        let entry: HistoryEntry | undefined;

        if ( !triggersToNotStoreInHistory.includes( event.id ) ) {
            entry = createHistoryEntry( event );
            pushHistory( entry );
        }

        getUtilityPopupContainer().insertAdjacentHTML( 'beforeend', body.html );

        const alert = document.querySelector( '.property-alert' ) as HTMLElement;

        this._setupAlert( alert, event, entry );

        if ( alert && alert.querySelector( 'form' ) ) {
            this._setupAlertForm( alert, event );
        }

        openUtilityPopup( alert );
    }

    /**
     * _setupAlert handles settings up the alert and contained buttons
     * @param {HTMLElement} alert - the alert element
     * @param {OpenAlertEvent} event
     * @returns {void}
     */
    _setupAlert( alert: HTMLElement, event: OpenAlertEvent, entry?: HistoryEntry ): void {
        window.dataLayerPush( {
            'event': 'form-open',
            'action': getActionNameForEvent( event ),
            'label': 'lang:' + getLanguage(),
            'value': 1,
        } );

        alert.querySelectorAll( '.property-alert .popup__close, .popup__container--property-alert-info button' ).forEach( ( btn: HTMLButtonElement ) => {
            btn.addEventListener( 'click', () => {
                this._closeAlert( alert, event, entry );
            } );
        } );

        alert.querySelectorAll( '.save-and-sign-in' ).forEach( ( btn: HTMLButtonElement ) => {
            btn.addEventListener( 'click', ( clickEvent ) => {
                clickEvent.preventDefault();

                this._closeAlert( alert, event, entry );

                document.dispatchEvent( new CustomEvent( 'twc:saveable', {
                    bubbles: true,
                    detail: {
                        element: this._alertableElement,
                        forceSave: true,
                    },
                } ) );

                document.dispatchEvent( new CustomEvent( 'twc:user-signin', {
                    bubbles: true,
                } ) );
            } );
        } );
    }

    /**
     * _setupAlertForm handles setting up the alert form ready for submission
     * @param {HTMLFormElement} form - the form element within the alert popup
     * @param {HTMLElement} alert - the alert element
     * @param {string} gaAction - the action to use when dispatching the GA event
     * @returns {void}
     */
    _setupAlertForm( alert: HTMLElement, openEvent: OpenAlertEvent ): void {
        const form = alert.querySelector( 'form' ) as HTMLFormElement;
        const submitBtn = form.querySelector( 'button[type="submit"]' ) as HTMLButtonElement;
        const popupContent = form.closest( '.popup__content' ) as HTMLElement;

        const validator = newValidator( form );

        const startLoading = () => {
            popupContent.classList.add( 'popup__content--loading', 'popup__content--disabled' );
            submitBtn.disabled = true;
            form.inert = true;
        };

        const stopLoading = () => {
            popupContent.classList.remove( 'popup__content--loading', 'popup__content--disabled' );
            submitBtn.disabled = false;
            form.inert = false;
        };

        const onSubmit = async ( event: Event ) => {
            event.preventDefault();

            if ( form.classList.contains( 'popup__form--loading' ) ) {
                return;
            }

            startLoading();

            const valid = validator.validate( null, false );
            const errors = validator.getErrors( null );

            if ( errors.length > 0 ) {
                let eventLabel = 'errors:' + errors.length;

                for ( let errorIndex = 0; errorIndex < errors.length; errorIndex++ ) {
                    const name = errors[ errorIndex ].input.name;
                    const value = errors[ errorIndex ].input.value;
                    eventLabel += ';' + name + '=' + value;
                }

                window.dataLayerPush( {
                    'event': 'form-submit',
                    'action': getActionNameForEvent( openEvent ) + '-errors',
                    'label': eventLabel,
                    'value': 1,
                } );
            }

            form.querySelectorAll( 'input,textarea' ).forEach( ( input: HTMLInputElement | HTMLTextAreaElement ) => {
                input.value = input.value.trim();
            } );

            if ( !valid ) {
                stopLoading();
                return;
            }

            const msgs = form.querySelector( '.messages' );

            if ( !msgs ) {
                throw new Error( 'failed to find alert message container' );
            }

            msgs.innerHTML = '';

            const formData = new FormData( form );

            if ( this._alertableElement && this._alertableElement.dataset.saveProperty ) {
                formData.append( 'type', this._alertableElement.dataset.saveProperty );
            }

            const isSavable = () => {
                const currentQueryParams = new URLSearchParams( window.location.search );
                const currentFilter = PropertySearchFilter.fromURLSearchParams( currentQueryParams );

                if ( currentFilter.area.length > 0 ) {
                    return false;
                }

                if ( this._alertableElement && this._alertableElement.dataset.saveValue ) {
                    const parsed = JSON.parse( this._alertableElement.dataset.saveValue );

                    if ( parsed[ 'LocationFilters' ] && Object.keys( parsed[ 'LocationFilters' ] ).length > 0 ) {
                        return false;
                    }

                    if ( parsed[ 'Area' ] && parsed[ 'Area' ][ 'coordinates' ] ) {
                        return false;
                    }
                }

                return true;
            };

            const searchIsSavable = isSavable();

            if ( searchIsSavable && this._alertableElement && this._alertableElement.dataset.saveValue ) {
                formData.append( 'filter', this._alertableElement.dataset.saveValue );
            }

            if ( searchIsSavable && this._alertableElement && this._alertableElement.dataset.saveMeta ) {
                formData.append( 'meta', this._alertableElement.dataset.saveMeta );
            }

            if ( searchIsSavable && this._alertableElement && this._alertableElement.dataset.saveDisplayname ) {
                formData.append( 'name', this._alertableElement.dataset.saveDisplayname );
            }

            const endpointPath = form.getAttribute( 'action' );

            if ( !endpointPath ) {
                throw new Error( 'no alert endpoint path found' );
            }

            const url = new URL( window.location.origin + endpointPath );

            const response: Response = await window.http( url, {
                method: 'POST',
                body: formData,
            } );

            if ( !response.ok ) {
                throw new Error( 'failed submitting alert form' );
            }

            const body = await response.json() as FormResponse;

            stopLoading();

            if ( body.error ) {
                msgs.insertAdjacentHTML( 'beforeend', `<div class="alert alert-warning">${body.error}</div>` );
                window.scroll( 0, msgs.getBoundingClientRect().top + window.scrollY - 200 );
                return;
            }

            if ( body.didyoumean && body.didyoumeanhtml ) {
                setupEmailSuggestionFromResult( {
                    emailSuggestion: body.didyoumean,
                    html: body.didyoumeanhtml,
                }, form );
                return;
            }

            let eventFormType = openEvent.id;

            if ( openEvent.id === TriggerDirectViaButton && openEvent.source ) {
                eventFormType += '-' + openEvent.source;
            }

            const emailTypes = [
                EmailTypePropertyAlert,
            ];

            const optInCheckbox = form.querySelector( 'input[name="opt-in-featured-mailshot"]' ) as HTMLInputElement;

            if ( optInCheckbox && optInCheckbox.checked ) {
                emailTypes.push( EmailTypeFeaturedProperties );
            }

            const eventParameters: GA4EventParameters = {
                'form_type': eventFormType,
                'form_location': 'popup',
                'email_types': emailTypes.sort().join( ',' ),
                'email_frequency': getEmailAlertFrequency( form ),
                ...body.eventParameters || {},
            };

            if ( openEvent.id === TriggerInactivity ) {
                const historyEntries: Array<HistoryEntry> = getHistory().entries.filter( ( entry ) => entry.id === TriggerInactivity );

                if ( historyEntries.length > 1 ) {
                    const valueInSeconds = triggerBlockGroups[ historyEntries.length - 2 ];

                    if ( valueInSeconds >= 3600 ) {
                        eventParameters[ 'trigger_block_group' ] = `${( valueInSeconds / 60 ) / 60}hr`;
                    } else {
                        eventParameters[ 'trigger_block_group' ] = `${valueInSeconds / 60}min`;
                    }
                } else {
                    eventParameters[ 'trigger_block_group' ] = 0;
                }

                if ( openEvent.parameters && openEvent.parameters.timeout ) {
                    eventParameters[ 'trigger_timeout' ] = openEvent.parameters.timeout;
                }
            }

            triggerEvent( EventNameMailshotSubscribe, eventParameters );

            window.dataLayerPush( {
                'event': 'form-submit',
                'action': getActionNameForEvent( openEvent ),
                'label': 'lang:' + getLanguage(),
                'value': 1,
            } );

            this._removeAlert( alert );

            this._removeQueryParam();

            document.body.insertAdjacentHTML( 'beforeend', body.html );

            const content = document.querySelector( '.property-alert-confirmation' );

            if ( !content ) {
                throw new Error( 'failed to find confirmation content' );
            }

            content.querySelectorAll( 'button' ).forEach( ( btn: HTMLButtonElement ) => {
                btn.addEventListener( 'click', () => {
                    content.remove();
                } );
            } );

            window.dispatchEvent( new CustomEvent( 'twc:update-saved-status', {
                bubbles: true,
            } ) );
        };

        form.addEventListener( 'submit', onSubmit.bind( this ) );
    }

    /**
     * _removeAlert removes the alert element from the DOM
     * @param alert - the alert element to remove
     */
    _removeAlert( alert: HTMLElement ): void {
        closeAllUtilityPopups();
        alert.remove();
    }

    /**
     * _closeAlert handles closing the alert, it dispatches a GA event and invokes the callback if provided
     * @param {HTMLElement} alert - the alert element
     * @param {string} gaAction - the action to use when dispatching the GA event
     * @param {function} callback - the callback function to invoice
     */
    _closeAlert( alert: HTMLElement, event: OpenAlertEvent, entry?: HistoryEntry ) {
        window.dataLayerPush( {
            'event': 'form-close',
            'action': getActionNameForEvent( event ),
            'label': 'lang:' + getLanguage(),
            'value': 1,
        } );

        if ( entry ) {
            const history = getHistory();

            history.entries = history.entries.map( ( e: HistoryEntry ) => {
                if ( e.id === entry.id && e.time === entry.time ) {
                    return {
                        ...e,
                        time: getCurrentTimestamp(),
                    };
                }
                return e;
            } );

            updateHistory( history );
        }

        this._removeAlert( alert );

        if ( typeof event.onPopupClosedCallback === 'function' ) {
            event.onPopupClosedCallback();
        }

        document.dispatchEvent( new CustomEvent( 'twc:popup:close-property-alert', {
            bubbles: true,
        } ) );
    }

    /**
     * _removeQueryParam removes the alert query parameter. This is usually present for a direct naviation URL.
     * @returns {void}
     */
    _removeQueryParam(): void {
        const currentURL = new URL( window.location.href );

        if ( !currentURL.searchParams.has( 'alert' ) ) {
            return;
        }

        const queryParam = currentURL.searchParams.get( 'alert' );

        if ( queryParam && queryParam === 'true' ) {
            currentURL.searchParams.delete( 'alert' );

            window.router.Replace( 'current', Object.assign( {}, window.router.currentState, {
                url: currentURL.toString(),
            } ) );
        }
    }

    /**
     * _canBeShown determines whether the alert can be shown for event triggered
     * @param {OpenAlertEvent} event
     * @returns {Promise<boolean>} whether to allow showing the alert for the event
     */
    async _canBeShown( event: OpenAlertEvent ): Promise<AlertShowResult> {
        if ( window.blockedTriggers && window.blockedTriggers.includes( event.id ) ) {
            return {
                show: false,
                reason: 'trigger-id-in-block-list',
                info: {
                    blockedTriggers: window.blockedTriggers,
                },
            };
        }

        if ( isConsentBannerVisible() || isContentPreferencesVisible() ) {
            return {
                show: false,
                reason: 'cookie-consent-visible',
            };
        }

        const alert: HTMLElement | null = document.querySelector( '#utility-popup-container .property-alert' );

        if ( alert ) {
            return {
                show: false,
                reason: 'existing-popup',
            };
        }

        const elementsBlocking: Array<HTMLElement> = Array.from( document.body.querySelectorAll<HTMLElement>( '[data-allow-alert-popup="false"]' ) ).filter( ( element: HTMLElement ) => isVisible( element ) );

        if ( elementsBlocking.length > 0 ) {
            return {
                show: false,
                reason: 'page-element-blocking',
                info: {
                    elements: elementsBlocking,
                },
            };
        }

        const pageBlockingInactivity = parseBool( window.document.body.dataset.allowAlertPopup || 'false' );

        if ( pageBlockingInactivity ) {
            return {
                show: false,
                reason: 'page-blocking',
            };
        }

        if ( getUser().isVerified ) {
            return {
                show: false,
                reason: 'user-verified',
            };
        }

        const now: number = getCurrentTimestamp();

        const sessionStart: number = getSessionStartTime();

        const initialBlockingTime: number = getInitialBlockingTime();

        const sessionStartDiff: number = Math.floor( now - sessionStart );

        if (
            initialBlockingTime > 0 &&
            triggersBlockedByInitialBlockingTime.includes( event.id ) &&
            sessionStartDiff < initialBlockingTime
        ) {
            return {
                show: false,
                reason: 'within-initial-blocking-time',
                info: {
                    initialBlockingTime,
                    now,
                    sessionStart,
                    sessionStartDiff,
                },
            };
        }

        const history: AlertPopupHistory = getHistory();

        const postTriggerBlockingTime: number = getPostTriggerBlockingTime();

        if ( postTriggerBlockingTime > 0 && !triggersAllowedToBypassPostTriggerBlocking.includes( event.id ) ) {
            let entriesFiltered = history.entries;

            switch ( event.id ) {
            case TriggerExitIntent: {
                entriesFiltered = entriesFiltered.filter( ( entry: HistoryEntry ) => entry.id === TriggerExitIntent );
                break;
            }
            default: {
                entriesFiltered = entriesFiltered.filter( ( entry: HistoryEntry ) => entry.id !== TriggerExitIntent );
            }
            }

            const entriesWithinBlockingTime = entriesFiltered.filter( ( entry: HistoryEntry ) => {
                if ( Math.floor( now - entry.time ) < postTriggerBlockingTime ) {
                    return true;
                }
                return false;
            } );

            if ( entriesWithinBlockingTime.length > 0 ) {
                return {
                    show: false,
                    reason: 'within-global-blocking-time',
                    info: {
                        now,
                        entriesWithinBlockingTime,
                        postTriggerBlockingTime,
                    },
                };
            }
        }

        const sameEntries = history.entries
            .filter( ( entry: HistoryEntry ) => entry.id !== TriggerDirectViaURL )
            .filter( ( entry: HistoryEntry ) => entry.id === event.id )
            .sort( ( a: HistoryEntry, b: HistoryEntry ) => a.time - b.time );

        const blockGroups: Array<number> = getTriggerBlockGroups();

        if ( blockGroups.length > 0 ) {
            if ( sameEntries.length >= ( blockGroups.length + 1 ) ) {
                return {
                    show: false,
                    reason: 'limit-reached',
                };
            }

            if ( sameEntries.length > 0 ) {
                const blockGroup = blockGroups[ ( sameEntries.length - 1 ) ];

                const entriesWithinBlockTime = sameEntries.filter( ( entry: HistoryEntry ) => {
                    if ( Math.ceil( now - entry.time ) < blockGroup ) {
                        return true;
                    }
                    return false;
                } );

                if ( entriesWithinBlockTime.length > 0 ) {
                    return {
                        show: false,
                        reason: 'within-trigger-blocking-time',
                        info: {
                            now,
                            entriesWithinBlockTime,
                        },
                    };
                }
            }
        }

        const emailAlreadyProvided: boolean = await hasUserProvidedEmail();

        if ( emailAlreadyProvided ) {
            return {
                show: false,
                reason: 'email-provided',
            };
        }

        return {
            show: true,
        };
    }
}

export default AlertPopupManager;

