import { navigateTo } from '../http/redirect';
import Logger from '../logging/logger';
import { validateURLString } from '../url/validate';
import { yieldToMainThread } from '../yield';
import { State, getState, newState } from './history';

function getDocumentDescription(): string {
    const desc: HTMLMetaElement | null = document.querySelector( 'meta[name="description"]' );

    if ( !desc ) {
        return '';
    }

    return desc.content;
}

type ScrollOffsetInfo = {
    isPopup: boolean;
    value: number,
}

type StatePosition = 'initial' | 'current' | 'previous';

export interface AppRouter {
    previousState: State;
    currentState: State;
    initialState: State;
    events: Record<string, any>;
    Replace: ( position: StatePosition, state: State ) => void;
    CaptureThen: ( callback: () => void ) => void;
    Rewind: () => void;
    GetScrollOffset: () => ScrollOffsetInfo;
}

class Router implements AppRouter {
    previousState: State;
    currentState: State;
    initialState: State;
    _onPopstateMerge = false;

    constructor() {
        this.previousState = newState();
        this.currentState = newState();
        this.initialState = newState();

        window.addEventListener( 'click', async ( event: MouseEvent ) => {
            if ( !event.target || !( event.target instanceof HTMLElement ) ) {
                return;
            }

            if ( !event.target.dataset.action || event.target.dataset.action !== 'navigate' ) {
                return;
            }

            event.preventDefault();

            this._next( this._getNextState( event ), event );

            await yieldToMainThread();
        } );

        window.addEventListener( 'twc:manual-navigation', this._handleNavigationEvent.bind( this ) );

        window.addEventListener( 'click', this._handleRewind.bind( this ) );

        window.addEventListener( 'click', this._back.bind( this ) );

        window.addEventListener( 'popstate', this._onPopstate.bind( this ) );

        const state: State = getState() || newState();
        state.title = document.title;
        state.description = getDocumentDescription();
        state.url = document.location.href;
        state.isRoutedPopup = false;
        state.meta = {};
        state.scrollPosition = 0;
        history.replaceState( state, document.title, state.url );

        this.currentState = getState();
        this.initialState = getState();

        document.dispatchEvent( new CustomEvent( 'twc:navigation-initialised', {
            detail: {
                router: this,
            },
            bubbles: true,
        } ) );

        Logger.info( '[router] Router Initialised: state %O', this.initialState );
    }

    /**
     * @description Replace modifies the current history entry
     * @param {StatePosition} position - "current" or "previous"
     * @param {State} state - the state to replace the current state with
     */
    async Replace( position: StatePosition, state: State ): Promise<void> {
        if ( !state.url ) {
            throw new Error( 'state.url is undefined' );
        }

        if ( state.url.includes( '#' ) ) {
            state.hasFragment = true;
            state.fragment = state.url.split( '#' )[ 1 ];
        }

        if ( state.container ) {
            delete state.container;
        }

        switch ( position ) {
        case 'initial':
            this.initialState = state;
            break;
        case 'current':
            this.currentState = state;
            history.replaceState( state, state.title, state.url );
            break;
        case 'previous':
            this.previousState = state;
            break;
        }

        this._onChange();
    }

    /**
     * CaptureThen updates the states scroll position before executing the callback function
     * @param {Function} callback - the callback functions to run after capturing
     */
    CaptureThen( callback: () => void ): void {
        const offset: ScrollOffsetInfo = this.GetScrollOffset();

        if ( offset.isPopup ) {
            this.currentState.scrollPosition = offset.value;
        } else {
            this.currentState.scrollPosition = offset.value;
            this.initialState.scrollPosition = offset.value;
        }

        if ( !callback ) {
            return;
        }

        callback();
    }

    /**
     * @description Rewind modifies the current history by returning to the state initialised with
     */
    async Rewind(): Promise<void> {
        this.previousState = Object.assign( {}, getState() );

        const detail = this.initialState;
        detail.previous = this.previousState;

        document.dispatchEvent( new CustomEvent( this.events.popup.close, {
            detail: detail,
            bubbles: true,
        } ) );

        await yieldToMainThread();

        history.pushState( this.initialState, this.initialState.title, this.initialState.url );

        document.dispatchEvent( new CustomEvent( this.events.popup.closeAfter, {
            detail: detail,
            bubbles: true,
        } ) );

        this.currentState = Object.assign( {}, getState() );

        this._onChange();
    }

    async _next( next: State, originalEvent?: Event ): Promise<void> {
        const offset: ScrollOffsetInfo = this.GetScrollOffset();

        if ( offset.isPopup ) {
            this.currentState.scrollPosition = offset.value;
        } else {
            this.currentState.scrollPosition = offset.value;
            this.initialState.scrollPosition = offset.value;
        }

        if ( this.currentState.isRoutedPopup && this.currentState.events && this.currentState.events.close.length > 0 ) {
            this.currentState.events.close.forEach( ( name: string ) => {
                document.dispatchEvent( new CustomEvent( name, {
                    detail: this.currentState,
                    bubbles: true,
                } ) );
            } );
        }

        if ( this.currentState.container ) {
            delete this.currentState.container;
        }

        history.replaceState( this.currentState, this.currentState.title, this.currentState.url );

        this.previousState = Object.assign( {}, getState() );

        if ( originalEvent && originalEvent.target && ( originalEvent.target as HTMLElement ).dataset.actionStateMode && ( originalEvent.target as HTMLElement ).dataset.actionStateMode === 'replace' ) {
            history.replaceState( next, next.title, next.url );
        } else {
            history.pushState( next, next.title, next.url );
        }

        this.currentState = Object.assign( {}, getState() );

        if ( !next.onlyUpdateState ) {
            document.dispatchEvent( new CustomEvent( this.events.navigation.next, {
                detail: {
                    ...next,
                    originalEvent: originalEvent,
                },
                bubbles: true,
            } ) );
        }

        Logger.info( '[router] Navigation Event: detail %O', {
            state: next,
            event: originalEvent,
        } );

        this._onChange();
    }

    /**
     * @description HandleRewind calls Rewind for event-based calls
     * @param {Event} event - the native MouseEvent event triggered
     */
    _handleRewind( event: Event ): void {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            return;
        }

        if ( !event.target.dataset.action || event.target.dataset.action !== 'navigate-rewind' ) {
            return;
        }

        event.preventDefault();

        this.Rewind();
    }

    /**
     * @description Back modified the current hisory by navigating backwards by 1
     * @param {MouseEvent} event - the native MouseEvent event triggered
     */
    _back( event: Event ): void {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            return;
        }

        if ( !event.target.dataset.action || event.target.dataset.action !== 'navigate-back' ) {
            return;
        }

        event.preventDefault();

        history.go( -1 );
    }

    /**
     * @description onPopState handles navigating using the browser back buttons
     * @param {PopStateEvent} event - the native PopStateEvent event triggered
     */
    async _onPopstate( event: PopStateEvent ): Promise<void> {
        if ( !event.state && !event.isTrusted ) {
            Logger.warn( '[router] Unhandled PopState Event %O', event );
        }

        const currentURL = new URL( window.location.href );

        let state = event.state;

        if ( !state && currentURL.hash.length > 0 ) {
            state = JSON.parse( JSON.stringify( this.currentState ) );
            state.url = currentURL.toString();
            await yieldToMainThread();
            history.replaceState( state, state.title, state.url );
            return;
        }

        if ( !state ) {
            return;
        }

        if ( this._onPopstateMerge ) {
            const currentPrevious = this.previousState;

            state.url = currentPrevious.url;
            state.meta = currentPrevious.meta;

            this._onPopstateMerge = false;
        }

        if ( Object.keys( this.previousState ).length === 0 ) {
            navigateTo( state.url );
            return;
        }

        const needRedirecting = ( this.previousState.isDirect && this.previousState.isDirect === true );

        if ( !state.isRoutedPopup && needRedirecting ) {
            this.currentState.isDirect = true;
        }

        this.previousState = this.currentState;
        this.currentState = state;

        this._onChange();

        if ( !state.isRoutedPopup ) {
            const previous = {
                previous: this.previousState,
            };

            state = JSON.parse( JSON.stringify( Object.assign( {}, state, previous ) ) );

            document.dispatchEvent( new CustomEvent( this.events.popup.close, {
                detail: state,
                bubbles: true,
            } ) );

            document.dispatchEvent( new CustomEvent( this.events.popup.closeAfter, {
                detail: state,
                bubbles: true,
            } ) );

            if ( !state.manualPopstate ) {
                return;
            }
        }

        if ( state.manualPopstate ) {
            if ( this.previousState.events && this.previousState.events.close.length > 0 ) {
                this.previousState.events.close.forEach( ( name: string ) => {
                    document.dispatchEvent( new CustomEvent( name, {
                        detail: this.previousState,
                        bubbles: true,
                    } ) );
                } );
            }

            document.dispatchEvent( new CustomEvent( 'twc:navigation-popstate', {
                bubbles: true,
                detail: {
                    state: this.currentState,
                    previousState: this.previousState,
                    originalEvent: event,
                },
            } ) );

            return;
        }

        if ( needRedirecting ) {
            this.currentState.isDirect = true;
            state = this.currentState;
        }

        if ( this.currentState.isRoutedPopup && this.previousState.events && this.previousState.events.close.length > 0 ) {
            this.previousState.events.close.forEach( ( name: string ) => {
                document.dispatchEvent( new CustomEvent( name, {
                    detail: this.previousState,
                    bubbles: true,
                } ) );
            } );
        }

        Logger.info( '[router] Navigation Event: detail %O', {
            state: state,
            event: event,
        } );

        document.dispatchEvent( new CustomEvent( this.events.navigation.next, {
            detail: state,
            bubbles: true,
        } ) );
    }

    /**
     * @description _onChange handles updating global state on every navigation change
     */
    _onChange(): void {
        document.title = this.currentState.title;
    }

    /**
     * @description _getNextState builds a state object from the given event
     * @param {MouseEvent} event - the native MouseEvent event triggered
     */
    _getNextState( event: Event ): State {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            throw new ReferenceError( 'No target for event' );
        }

        if ( ( event.target instanceof HTMLAnchorElement && !event.target.href ) && !event.target.dataset.actionUrl ) {
            throw new ReferenceError( 'No href or data-action-url attribute found' );
        }

        const state: State = Object.assign( {}, getState(), newState() );

        if ( event.target.dataset.actionUrl && event.target.dataset.actionUrl[ 0 ] === '.' ) {
            const actionTarget: HTMLElement | null = document.querySelector( event.target.dataset.actionUrl );

            if ( !actionTarget ) {
                throw new Error( 'failed to find element for actionUrl' );
            }

            state.url = actionTarget.dataset.baseUrl as string;
        } else {
            state.url = ( event.target as HTMLAnchorElement ).href ? ( event.target as HTMLAnchorElement ).href : event.target.dataset.actionUrl as string;
        }

        let url: URL;

        if ( state.url.startsWith( '/' ) ) {
            url = new URL( window.location.origin + state.url );
        } else {
            url = new URL( state.url );
        }

        const err = validateURLString( url.toString() );

        if ( err !== null ) {
            throw new Error( 'validating new state URL failed with err: ' + err.message );
        }

        state.url = url.toString();

        if ( state.url.includes( '#' ) ) {
            state.hasFragment = true;
            state.fragment = state.url.split( '#' )[ 1 ];
        }

        state.title = event.target.dataset.actionTitle || document.title;

        state.events = {
            open: [],
            close: [],
            dataLayer: [],
        };

        if ( event.target.dataset.actionOpenEvents ) {
            state.events.open = event.target.dataset.actionOpenEvents.split( ',' );
        }

        if ( event.target.dataset.actionCloseEvents ) {
            state.events.close = event.target.dataset.actionCloseEvents.split( ',' );
        }

        if ( event.target.dataset.actionAnalyticsEvent ) {
            state.events.dataLayer.push( JSON.parse( event.target.dataset.actionAnalyticsEvent ) );
        }

        if ( event.target.dataset.actionParams ) {
            state.additionalParams = event.target.dataset.actionParams;
        }

        return state;
    }

    /**
     * @returns {ScrollOffsetInfo} - the current scroll offset and if the page is a popup
     */
    GetScrollOffset(): ScrollOffsetInfo {
        const popupContainer = document.querySelector( '#page-popup-container .single-content' );

        const isPopup = this.currentState.isRoutedPopup && popupContainer !== null;

        let scrollOffset = 0;

        if ( isPopup ) {
            const popup = document.querySelector( '.pagepopup__container--details' );

            if ( !popup ) {
                throw new Error( 'failed to find popup container' );
            }

            let offset = Math.floor( popupContainer.getBoundingClientRect().top );

            const header = popup.querySelector( '.header' );

            if ( !header ) {
                throw new Error( 'failed to find header element' );
            }

            const rect = header.getBoundingClientRect();
            const start = Math.floor( rect.height );

            const unscrolledOffset = start; // + top;

            if ( offset < unscrolledOffset ) {
                const newOffset = offset - unscrolledOffset;
                offset = newOffset < 0 ? newOffset * -1 : newOffset;
            } else {
                offset = 0;
            }

            scrollOffset = offset;
        } else {
            scrollOffset = window.scrollY || document.documentElement.scrollTop || 0;
        }

        return {
            isPopup: isPopup,
            value: scrollOffset,
        };
    }

    _handleNavigationEvent( event: CustomEvent ): void {
        if ( event.target && event.target instanceof HTMLElement && event.target.dataset.action && event.target.dataset.action === 'navigate-rewind' ) {
            this._handleRewind( event );
            return;
        }

        if ( event.target && event.target instanceof HTMLElement && event.target.dataset.action && event.target.dataset.action === 'navigate-back' ) {
            this._back( event );
            return;
        }

        this._next( Object.assign( {}, getState(), {
            title: event.detail.title,
            description: event.detail.description,
            url: event.detail.url,
            isRoutedPopup: event.detail.isRoutedPopup,
            meta: event.detail.meta,
            scrollPosition: event.detail.scrollPosition,
            manualPopstate: event.detail.manualPopstate,
            onlyUpdateState: event.detail.onlyUpdateState,
        } ) as State );
    }

    selectors = {
        nextListener: '[data-action="navigate"]',
        backListener: '[data-action="navigate-back"]',
        rewindListener: '[data-action="navigate-rewind"]',
    };

    events = {
        initialised: 'twc:navigation-initialised',
        navigation: {
            next: 'twc:navigation',
        },
        popup: {
            open: 'twc:popup:open',
            close: 'twc:popup:close',
            closeAfter: 'twc:popup:close:after',
        },
    };
}

export default Router;
