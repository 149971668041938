import Logger from '../logging/logger';
import { getPagePopupContainer } from '../popups/page-popup';
import { yieldToMainThread } from '../yield';

/**
 * Load the page with ?google_console=1 or ?fuse_debug=true for debugging
 * Publift/Fuse API documentation: https://docs.publift.com/fuse-web
 */
export class AdManager {
    constructor() {
        Logger.info( 'Initialising Ads' );

        document.addEventListener( 'twc:popup:open', () => {
            window.adManager.pageInit( getPagePopupContainer() );
        } );

        document.addEventListener( 'twc:popup:close', () => {
            window.adManager.pageInit( document.body );
        } );
    }

    registerZones( container: HTMLElement ): Array<HTMLDivElement> {
        const slots = Array.from( container.querySelectorAll( '.advert__slot' ) ) as Array<HTMLDivElement>;

        const slotIDs: Array<string> = slots.map( ( slot: HTMLDivElement ) => slot.id );

        window.fusetag.que.push( function(): void {
            slotIDs.forEach( async ( slotID: string ) => {
                window.fusetag.registerZone( slotID );
                await yieldToMainThread();
            } );
        } );

        Logger.info( `Registered ${slots.length} ads`, slotIDs );

        return slots;
    }

    async pageInit( container: HTMLElement ): Promise<void> {
        const slots = this.registerZones( container );

        await yieldToMainThread();

        const targetingAttributes: Array<FuseTargetingPair> = [];

        slots.forEach( ( slot: HTMLDivElement ) => {
            const targetingAttrKeys: Array<string> = Object.keys( slot.dataset ).filter( ( key: string ) => key.startsWith( 'targeting' ) );

            targetingAttrKeys.forEach( ( rawKey: string ) => {
                const key = rawKey.replaceAll( 'targeting', '' ).toLowerCase();
                const value = slot.dataset[ rawKey ] as string;

                const isExisting = targetingAttributes.some( ( attr: FuseTargetingPair ) => {
                    return attr.key === key && attr.value === value;
                } );

                if ( isExisting ) {
                    return;
                }

                const targetingPair: FuseTargetingPair = {
                    key: key,
                    value: value,
                };

                targetingAttributes.push( targetingPair );
            } );
        } );

        const slotIDs: Array<string> = slots.map( ( slot: HTMLDivElement ) => slot.id );

        const fuseInitOptions: FusePageInitOptions = {
            blockingFuseIds: slotIDs,
            pageTargets: targetingAttributes,
        };

        Logger.info( 'Running fuse pageInit', fuseInitOptions );

        window.fusetag.que.push( async function() {
            window.fusetag.pageInit( fuseInitOptions );
            await yieldToMainThread();
        } );
    }
}

export default function initAdManager(): void {
    if ( !window.adManager ) {
        window.adManager = new AdManager();
    }
}

