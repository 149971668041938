import initDirectoryEnquiryForm from '../directory/enquiry';
import Logger from '../logging/logger';
import initSimpleForm from '../simpleform';

function onNewcontentloaded( event ) {
    const detail = event.detail || {};
    const container = detail.container || document;

    Logger.info( 'Event Triggered: onNewContentLoaded', detail );

    const directoryEnquiryForms = document.getElementsByClassName( 'directory-enquiry' );
    for ( let directoryForm = 0; directoryForm < directoryEnquiryForms.length; directoryForm++ ) {
        initDirectoryEnquiryForm( directoryEnquiryForms[ directoryForm ] );
    }

    container.querySelectorAll( '.twc__simple-form' ).forEach( ( form ) => initSimpleForm( form ) );

    // Initialise new Ladda buttons
    if ( Array.from( document.querySelectorAll( 'button.ladda-button' ) ).some( ( btn ) => !btn.dataset.style ) ) {
        Ladda.bind( 'button.ladda-button', {
            timeout: 750,
        } );
    }

    // Set Saved Status for newly loaded content
    window.dispatchEvent( new CustomEvent( 'twc:update-saved-status', {
        bubbles: true,
        detail: {
            container: container,
        },
    } ) );
}

export default onNewcontentloaded;
