import { navigateTo } from '../../../http/redirect';
import { getOfferTypeForForm } from '../../../property/search-filters';
import { isMapMode } from '../../util';

export function onDYSButtonClick( form: HTMLFormElement, eventTarget: EventTarget ): void {
    if ( !isMapMode() ) {
        const urls = ( window.typeaheadOptions as Array<any> ).find( ( option ) => option.id === 'draw-your-search' ).urls;
        navigateTo( urls[ getOfferTypeForForm( form ) ] );
    } else {
        eventTarget.dispatchEvent( new CustomEvent( 'twc:mapsearch:draw-your-search:activate', { bubbles: true } ) );
    }
}
