import Awesomplete from 'awesomplete';
import AwesompleteTypeahead from './awesomplete';
import { escapeRegExpString } from '../regexp';
import { captureInputState, toggleInputReset } from '../forms/input';
import { toOption } from './suggestion-types';
import { Suggestion } from './suggestion-types/common';
import { selectTypeaheadOption } from './suggestion-types/location';
import { resolveUsersVisitorInfo } from '../user/visitor-info';
import requestIdleCallback from '../shims/requestIdleCallback';
import { yieldToMainThread } from '../yield';
import ResetableTypeaheadInput from './resetable';

export type TypeaheadOptions = {
    limit: number;
    defaultItems?: [],
    inputHandlers: Array<InputHandler>,
    filter?: ( suggestion: Suggestion, input: string ) => boolean;
}

export type AwesompleteEvent = {
    text: Suggestion;
    preventDefault: () => void;
}

export interface InputHandler {
    test: ( query: string ) => boolean;
    invoke: ( query: string ) => Promise<Array<Suggestion>>;
}

function newAwesomplete( input: HTMLInputElement, options: TypeaheadOptions ) {
    const awesomplete = new AwesompleteTypeahead( input, {
        minChars: 0,
        limit: options.limit,
        replace: function( suggestion: Suggestion ) {
            this.input.value = suggestion.label;
        },
        sort: function( suggestion: Suggestion ) {
            return suggestion;
        },
        filter: function( suggestion: Suggestion, input: string ) {
            return true;
        },
        item: function( text: Suggestion, input: string, id: number ) {
            const inputTrimmed = input.trim();

            let markedText = text.label;
            let marked = false;

            if ( inputTrimmed.length > 0 && text.label.toLocaleLowerCase().includes( inputTrimmed ) ) {
                markedText = text.label.replace( new RegExp( escapeRegExpString( inputTrimmed ), 'gi' ), '<mark>$&</mark>' );
                marked = true;
            }

            const suggestion: Suggestion = this._list.find( ( el: Suggestion ) => el.value === text.value );

            if ( !suggestion ) {
                throw new Error( 'couldnt find a suggestion that matches' );
            }

            const highlightElement = newHighlightElement( markedText, marked );

            const element = suggestion.item( highlightElement, this.count, id );

            if ( suggestion.allowedCountries && suggestion.allowedCountries.length > 0 ) {
                handleCountrySpecificSuggestion( element, suggestion );
            }

            return element;
        },
    } );

    if ( options.filter ) {
        awesomplete.filter = options.filter;
    }

    awesomplete.ul.removeAttribute( 'hidden' );

    return awesomplete;
}

function newHighlightElement( content: string, createMark: boolean ): HTMLElement {
    const element = document.createElement( 'span' );
    element.className = 'typeahead__mark';

    if ( createMark ) {
        element.innerHTML = content;
    } else {
        element.innerText = content;
    }

    return element;
}

async function handleCountrySpecificSuggestion( suggestionElement: HTMLElement, suggestion: Suggestion ): Promise<void> {
    suggestionElement.classList.add( 'typeahead__option--disabled' );

    requestIdleCallback( async () => {
        const vInfo = await resolveUsersVisitorInfo();

        if ( suggestion.allowedCountries && !suggestion.allowedCountries.includes( vInfo.country.toLowerCase() ) ) {
            suggestionElement.classList.add( 'typeahead__option--hidden' );
        } else {
            suggestionElement.classList.remove( 'typeahead__option--disabled' );
        }
    } );
}

function prepareQuery( str: string ): string {
    if ( str.length > 0 ) {
        str = str.trim();
    }
    return str;
}

class Typeahead {
    _options: TypeaheadOptions;
    _awesomeplete: Awesomplete;
    _input: HTMLInputElement;
    _valueInput: HTMLInputElement;
    _form: HTMLFormElement;
    _container: HTMLElement;
    _defaultSuggestions: Array<Suggestion> = [];
    _inputTimeout: number;
    _reset?: ResetableTypeaheadInput;
    _autoSubmit: boolean;
    _inputHandlers: Array<InputHandler>;

    constructor( input: HTMLInputElement, container: HTMLElement, options: TypeaheadOptions ) {
        this._input = input;
        this._container = container;
        this._options = options;
        this._inputHandlers = options.inputHandlers || [];
        this._awesomeplete = newAwesomplete( this._input, this._options );

        if ( this._container instanceof HTMLFormElement === false ) {
            const parentForm = container.closest( 'form' ) as HTMLFormElement;

            if ( !parentForm ) {
                throw new Error( 'failed to find parent form' );
            }

            this._form = parentForm;
        } else {
            this._form = container as HTMLFormElement;
        }

        this._autoSubmit = ( !!this._form.dataset.autoSubmit && this._form.dataset.autoSubmit === 'true' );
        this._valueInput = container.querySelector( 'input[type=hidden]' ) as HTMLInputElement;

        if ( window.typeaheadOptions ) {
            this._defaultSuggestions = ( window.typeaheadOptions as Array<Suggestion> ).map( toOption );
            this._awesomeplete.list = this._defaultSuggestions;

            // call item to create the <li> element
            this._defaultSuggestions.forEach( ( item: Suggestion, index: number ) => {
                item.item( newHighlightElement( item.label, false ), this._awesomeplete.count, index );
            } );
        }

        if ( this._container.dataset.typeaheadWithReset === 'true' ) {
            this._reset = new ResetableTypeaheadInput( {
                stateful: this._container.dataset.typeaheadStatefulReset === 'true',
                typeaheadInput: this._input,
                valueInput: this._valueInput,
            } );
        }

        this._input.addEventListener( 'input', this._onInputInput.bind( this ) );
        this._input.addEventListener( 'focus', this._onInputFocus.bind( this ) );
        this._input.addEventListener( 'change', this._onInputChange.bind( this ) );

        this._input.addEventListener( 'awesomplete-select', this._onTypeaheadSelect.bind( this ) );
        this._input.addEventListener( 'awesomplete-selectcomplete', this._onTypeaheadSelectComplete.bind( this ) );

        this._form.addEventListener( 'twc:typeahead:set-value', this._onSetValue.bind( this ) );
        this._form.addEventListener( 'submit', this._onFormSubmit.bind( this ) );

        window.addEventListener( 'pageshow', ( event: PageTransitionEvent ) => {
            if ( event.persisted ) {
                this.stopLoading();
            }
        } );

        this._input.dataset.initialised = 'true';
    }

    _onFormSubmit( event: SubmitEvent ): void {
        this.stopLoading();
    }

    _onSetValue( event: CustomEvent ): void {
        this._input.value = event.detail.label;

        selectTypeaheadOption( this._form, {
            ...event.detail.state,
            label: event.detail.label,
        } );

        if ( this._reset ) {
            this._reset.setState( captureInputState( this._valueInput ) );
        }

        toggleInputReset( this._input );
    }

    _onTypeaheadSelect( event: AwesompleteEvent ): void {
        const itemID = event.text.value;
        const suggestion: Suggestion = this._awesomeplete._list.find( ( el: Suggestion ) => el.value === itemID );

        if ( !suggestion ) {
            throw new Error( 'failed to find typeahead item with id: ' + itemID );
        }

        const selectable = suggestion.select( this, event );

        if ( !selectable ) {
            event.preventDefault();
        }
    }

    _onTypeaheadSelectComplete( event: AwesompleteEvent ): void {
        this._input.blur();

        if ( this._reset ) {
            this._reset.block( true );
        }

        const itemID = event.text.value;

        const el: Suggestion = this._awesomeplete._list.find( ( el: Suggestion ) => el.value === itemID );

        if ( !el ) {
            throw new Error( 'failed to find typeahead item with id: ' + itemID );
        }

        this._input.value = el.label;
        this._input.dataset.previousValue = '';

        el.selected( this );

        this._input.dispatchEvent( new CustomEvent( 'twc:typeahead:value-selected', {
            bubbles: true,
            detail: {
                value: this._valueInput.value,
                label: this._input.value,
            },
        } ) );
    }

    async _onInputInput( event: Event ): Promise<void> {
        window.clearTimeout( this._inputTimeout );

        let query = this._input.value;

        if ( query.length > 0 ) {
            query = prepareQuery( query );
        }

        if ( query.length === 0 && this._defaultSuggestions.length > 0 ) {
            await this._setListToDefaultSuggestions();
        }

        if ( this._container.dataset.typeaheadHideResults && this._container.dataset.typeaheadHideResults === 'true' ) {
            if ( query.length === 0 ) {
                this._awesomeplete.open();
            } else {
                this._awesomeplete.close();
            }
            return;
        }

        if ( query.length < 2 ) {
            this._awesomeplete.open();
            await yieldToMainThread();
            return;
        }

        this._inputTimeout = window.setTimeout( async () => {
            await this._search( query );
            await yieldToMainThread();
        }, 500 );
    }

    _onInputChange( event: Event ): void {
        if ( this._input.value.length === 0 ) {
            this._input.dispatchEvent( new CustomEvent( 'twc:typeahead-deselect', { bubbles: true } ) );
        }
    }

    async _onInputFocus( event: Event ): Promise<void> {
        let query = this._input.value;

        if ( query.length > 0 ) {
            query = prepareQuery( query );
        }

        if ( query.length < 2 && this._defaultSuggestions.length > 0 ) {
            await this._setListToDefaultSuggestions();
            this._awesomeplete.open();
        }

        if ( query.length >= 2 && !this._awesomeplete.isOpened ) {
            this._awesomeplete.open();
        }

        await yieldToMainThread();

        this._inputTimeout = window.setTimeout( async () => {
            await this._search( query );
            await yieldToMainThread();
        }, 500 );
    }

    async _search( query: string ): Promise<void> {
        // Searching when these are activate will return useless results to the user
        const locationIDValuesToIgnore = [
            'search-this-area',
            'draw-your-search',
            'map-area',
        ];

        if ( query.length < 2 || locationIDValuesToIgnore.includes( this._valueInput.value ) ) {
            this.stopLoading();
            return;
        }

        this.startLoading();

        const handler = this._inputHandlers.find( ( handler ) => handler.test( query ) );

        if ( !handler ) {
            this.stopLoading();
            return;
        }

        const results = await handler.invoke( query );

        this._awesomeplete.list = results;

        await yieldToMainThread();

        this.stopLoading();
    }

    startLoading(): void {
        this._container.classList.add( 'typeahead--loading' );
    }

    stopLoading(): void {
        this._container.classList.remove( 'typeahead--loading' );
    }

    async _setListToDefaultSuggestions(): Promise<void> {
        if ( this._awesomeplete.ul.children.length === 0 ) {
            this._awesomeplete.evaluate();
        }
        if ( this._awesomeplete._list && this._awesomeplete._list.some( ( s: Suggestion ) => s.value === 'spain' ) ) {
            return;
        }
        this._awesomeplete.list = this._defaultSuggestions;
        await yieldToMainThread();
    }
}

export default Typeahead;
