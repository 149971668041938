import showErrorPopup from './error';
import { getPagePopupContainer } from './page-popup';
import { yieldToMainThread } from '../yield';
import requestIdleCallback from '../shims/requestIdleCallback';
import Logger from '../logging/logger';
import { loadCss } from '../assets/css';
import { triggerPageView } from '../analytics/events';

type AssetDependency = {
    event: RegExp;
    load?: ( self: AssetDependency, event: CustomEvent ) => boolean;
    scripts?: Array<string>;
    css?: Array<string>;
}

function isInitialRouterEvent( eventToMatch: RegExp ): boolean {
    return (
        window.router.initialState.isRoutedPopup &&
        window.router.initialState.isDirect &&
        window.router.initialState.events.open.some( ( e ) => e.match( eventToMatch ) )
    );
}

const assetDependencies: Array<AssetDependency> = [
    {
        event: new RegExp( /(open-property|open-property-contact)$/ ),
        load: ( self: AssetDependency, event: CustomEvent ): boolean => {
            if ( isInitialRouterEvent( self.event ) ) {
                return false;
            }
            return true;
        },
        scripts: [
            window.assetsPrefix + '/js/property-details-lazy.js',
        ],
    },
    {
        event: new RegExp( /open-location/ ),
        load: ( self: AssetDependency, event: CustomEvent ): boolean => {
            if ( isInitialRouterEvent( self.event ) ) {
                return false;
            }
            return true;
        },
        css: [
            'https://api.mapbox.com/mapbox-gl-js/v1.13.2/mapbox-gl.css',
        ],
    },
];

export default async function( event: CustomEvent ) {
    try {
        const detail = event.detail || {};

        const url = new URL( detail.url );

        const isDirect = ( detail.isDirect && detail.isDirect === true );

        if ( isDirect ) {
            url.searchParams.set( 'directlinks', 'true' );
        }

        if ( detail.additionalParams ) {
            new URLSearchParams( detail.additionalParams ).forEach( ( value: string, key: string ) => {
                url.searchParams.set( key, value );
            } );
        }

        const assetPromises: Array<Promise<Event>> = [];

        if ( !isDirect && detail.events ) {
            let eventsThatWillTrigger: Array<string> = [];

            Object.keys( detail.events ).forEach( ( key ) => {
                eventsThatWillTrigger = [
                    ...eventsThatWillTrigger,
                    detail.events[ key ],
                ];
            } );

            const dependencies = assetDependencies.filter( ( dependency: AssetDependency ) => {
                return eventsThatWillTrigger.some( ( eventName: string ) => dependency.event.test( eventName ) );
            } ).filter( ( dependency: AssetDependency ) => {
                if ( dependency.load ) {
                    return dependency.load( dependency, event );
                }
                return true;
            } );

            dependencies.forEach( ( dependency: AssetDependency ) => {
                if ( dependency.scripts ) {
                    dependency.scripts.forEach( ( src: string ) => {
                        if ( document.querySelector( `head > script[src="${src}"]` ) ) {
                            return;
                        }

                        Logger.info( `Loading script with src "${src}"` );

                        const script = document.createElement( 'script' );
                        script.src = src;
                        script.async = true;

                        assetPromises.push( new Promise( function( resolve, reject ) {
                            script.onload = resolve;
                            script.onerror = reject;
                        } ) );

                        document.head.appendChild( script );
                    } );
                }

                if ( dependency.css ) {
                    dependency.css.forEach( ( src: string ) => {
                        if ( document.querySelector( `head > link[rel="stylesheet"][href="${src}"]` ) ) {
                            return;
                        }

                        Logger.info( `[app] Loading stylesheet with src "${src}"` );

                        loadCss( src );
                    } );
                }
            } );
        }

        const wrapper = getPagePopupContainer();

        const popupContent = wrapper.querySelector( '.pagepopup__content' ) as HTMLElement;

        wrapper.classList.add( 'pagepopup__wrapper--open', 'pagepopup__wrapper--loading' );

        document.body.classList.add( 'page-popup-open' );

        await yieldToMainThread();

        const results = await Promise.all( [
            ...assetPromises,
            window.http( url ),
        ] );

        const response = results.find( ( result ) => result instanceof Response );

        if ( !response ) {
            throw new Error( 'failed to find http response' );
        }

        if ( response.status !== 200 ) {
            throw new Error( `request for ${url} failed with status ${response.status}` );
        }

        const html = await response.text();

        const range = document.createRange();
        range.selectNode( popupContent );

        popupContent.innerHTML = '';
        popupContent.appendChild( range.createContextualFragment( html ) );

        await yieldToMainThread();

        triggerPageView();

        // Dispatch type specific events
        if ( detail.events && detail.events.open.length > 0 ) {
            detail.events.open.forEach( async function( name: string, index: number ) {
                document.dispatchEvent( new CustomEvent( name, {
                    detail: detail,
                    bubbles: true,
                } ) );

                if ( index > 0 ) {
                    await yieldToMainThread();
                }
            } );
        }

        const e = {
            bubbles: true,
            detail: {
                container: wrapper,
                srcEvent: detail,
            },
        };

        // Dispatch generic popup event
        document.dispatchEvent( new CustomEvent( 'twc:newcontentloaded', e ) );

        await yieldToMainThread();

        document.dispatchEvent( new CustomEvent( window.router.events.popup.open, e ) );

        await yieldToMainThread();

        const state = JSON.parse( JSON.stringify( history.state ) );

        const contentElement = wrapper.querySelector( '.pagepopup__contentinner' ) as HTMLElement;

        if ( contentElement && contentElement.dataset.popupTitle ) {
            state.title = contentElement.dataset.popupTitle;
        } else {
            state.title = document.title;
        }

        window.router.Replace( 'current', state );

        requestIdleCallback( () => {
            wrapper.classList.remove( 'pagepopup__wrapper--loading' );
        } );

        requestIdleCallback( () => {
            if ( detail.events && detail.events.dataLayer && detail.events.dataLayer.length > 0 ) {
                detail.events.dataLayer.forEach( function( dataEvent ) {
                    window.dataLayerPush( dataEvent );
                } );
            }
        } );
    } catch ( error ) {
        showErrorPopup();
        throw error;
    }
}
