import requestIdleCallback from '../shims/requestIdleCallback';
import initSimpleForm from '../simpleform';
import { getHTML, getUtilityPopupContainer } from '../popups/utility-popups';
import {
    closeAccountMenu,
    closeAllUtilityPopups,
    closeSideNav,
    openAccountMenu,
    openUtilityPopup,
} from '../popups/utils';

function handleForgot(): void {
    document.addEventListener( 'click', async ( event: Event ) => {
        if ( !event.target || !( event.target instanceof HTMLElement ) || !event.target.classList.contains( 'forgot' ) ) {
            return;
        }

        let passwordResetPopup = document.querySelector( '.popup__container--password-reset' );

        if ( !passwordResetPopup ) {
            const popupContainer = getUtilityPopupContainer();
            passwordResetPopup = await getHTML( 'password-reset-popup' );
            popupContainer.appendChild( passwordResetPopup );

            const form = passwordResetPopup.querySelector<HTMLFormElement>( '.twc__simple-form' );
            if ( form ) {
                initSimpleForm( form );
            }
        }

        if ( !passwordResetPopup ) {
            return;
        }

        await closeSideNav();

        closeAccountMenu();

        closeAllUtilityPopups( false );

        passwordResetPopup.querySelectorAll( '.popup__close' ).forEach( ( btn ) => btn.addEventListener( 'click', () => closeAllUtilityPopups() ) );

        openUtilityPopup( passwordResetPopup );
    } );
}

function handleMyAccount(): void {
    const myAccountBtnHeader = document.querySelector( '.account' );
    const myAccountBtnSideBar = document.querySelector( '.my-account-mobile' );

    const handle = async ( event: Event ) => {
        const close = function( event: Event ) {
            if ( event.target && event.target instanceof HTMLElement && event.target.closest( '.account-wrap' ) ) {
                return;
            }

            closeAccountMenu();
            document.querySelectorAll<HTMLAnchorElement | HTMLButtonElement>( 'a,button' ).forEach( ( clickable ) => clickable.removeEventListener( 'click', close ) );
        };

        if ( !document.body.classList.contains( 'my-account-open' ) ) {
            openAccountMenu();

            document.querySelectorAll<HTMLAnchorElement | HTMLButtonElement>( 'a,button' ).forEach( ( clickable ) => clickable.addEventListener( 'click', close ) );
        } else {
            closeAccountMenu();
        }

        requestIdleCallback( async () => {
            await closeSideNav();
        } );
    };

    if ( myAccountBtnHeader ) {
        myAccountBtnHeader.addEventListener( 'click', handle );
    }

    if ( myAccountBtnSideBar ) {
        myAccountBtnSideBar.addEventListener( 'click', handle );
    }
}

async function handleSignUp(): Promise<void> {
    async function open() {
        await closeSideNav();

        closeAccountMenu();

        closeAllUtilityPopups( false );

        let signUpPopup = document.querySelector( '.popup__container--signup' );

        if ( !signUpPopup ) {
            const popupContainer = getUtilityPopupContainer();
            signUpPopup = await getHTML( 'signup-popup' );
            popupContainer.appendChild( signUpPopup );

            const form = signUpPopup.querySelector<HTMLFormElement>( '.twc__simple-form' );
            if ( form ) {
                initSimpleForm( form );
            }
        }

        if ( !signUpPopup ) {
            return;
        }

        signUpPopup.querySelectorAll( '.popup__close' ).forEach( ( btn ) => btn.addEventListener( 'click', () => closeAllUtilityPopups() ) );

        openUtilityPopup( signUpPopup );
    }

    document.addEventListener( 'click', function( event ) {
        if ( !event.target || !( event.target instanceof HTMLElement ) || !event.target.classList.contains( 'sign-up' ) ) {
            return;
        }
        open();
    } );
}

function handleLogIn(): void {
    async function open(): Promise<void> {
        let logInPopup = document.querySelector( '.popup__container--signin' );

        if ( !logInPopup ) {
            const popupContainer = getUtilityPopupContainer();
            logInPopup = await getHTML( 'signin-popup' );
            popupContainer.appendChild( logInPopup );

            const form = logInPopup.querySelector<HTMLFormElement>( '.twc__simple-form' );
            if ( form ) {
                initSimpleForm( form );
            }
        }

        if ( !logInPopup ) {
            return;
        }

        logInPopup.querySelectorAll( '.popup__close' ).forEach( ( btn ) => btn.addEventListener( 'click', () => closeAllUtilityPopups() ) );

        await closeSideNav();

        closeAccountMenu();

        closeAllUtilityPopups( false );

        openUtilityPopup( logInPopup );
    }

    window.addEventListener( 'twc:user-signin', open );

    document.addEventListener( 'click', async function handleOpen( event: Event ) {
        if ( !event.target || !( event.target instanceof HTMLElement ) || !event.target.classList.contains( 'log-in' ) ) {
            return;
        }

        event.preventDefault();

        await open();
    } );
}

export default function accountControls(): void {
    handleForgot();
    handleSignUp();
    handleLogIn();
    handleMyAccount();
}
