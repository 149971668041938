import requestIdleCallback from '../shims/requestIdleCallback';
import { yieldToMainThread } from '../yield';

class CollapsingElements {
    constructor() {
        document.addEventListener( 'click', async ( event: Event ): Promise<void> => {
            if ( !event.target ) {
                return;
            }

            const target = event.target as HTMLElement;

            if ( !target.dataset.toggle || target.dataset.toggle !== 'collapse' ) {
                return;
            }

            await this._trigger( target, false );
        } );

        window.addEventListener( 'twc:collapse:collapse-all', ( event: CustomEvent ) => {
            document.querySelectorAll<HTMLButtonElement>( 'button[aria-expanded="true"][data-toggle="collapse"]' ).forEach( async ( btn: HTMLButtonElement ) => {
                if ( btn.dataset.ignoreCollapseAllEvent && btn.dataset.ignoreCollapseAllEvent === 'true' ) {
                    return;
                }
                await this._trigger( btn, true );
            } );
        } );

        document.querySelectorAll<HTMLButtonElement>( CollapsingElements.selectors.btn ).forEach( ( element: HTMLElement ) => {
            element.addEventListener( 'collapse:collapsed', async ( event: CustomEvent ) => {
                await this._trigger( element, true );
            } );
        } );
    }

    async _trigger( element: HTMLElement, forceClose: boolean ): Promise<void> {
        if ( !element.dataset.target ) {
            return;
        }

        const targetSelector = element.dataset.target;

        const target = document.querySelector( targetSelector );

        const allBtns = Array.from( document.querySelectorAll<HTMLButtonElement>( CollapsingElements.selectors.btn + `[data-target="${targetSelector}"]` ) );

        let isOpen = !element.classList.contains( 'collapsed' );

        if ( forceClose ) {
            isOpen = true;
        }

        if ( isOpen && target ) {
            target.classList.remove( 'in' );
        }

        if ( !isOpen && target ) {
            target.classList.add( 'in' );
        }

        await yieldToMainThread();

        if ( isOpen ) {
            requestIdleCallback( () => {
                allBtns.filter( ( btn ) => !btn.classList.contains( 'collapsed' ) ).forEach( ( btn ) => {
                    btn.classList.add( 'collapsed' );
                    btn.ariaExpanded = 'false';
                } );
            } );
        }

        if ( !isOpen ) {
            requestIdleCallback( () => {
                allBtns.filter( ( btn ) => btn.classList.contains( 'collapsed' ) ).forEach( ( btn ) => {
                    btn.classList.remove( 'collapsed' );
                    btn.ariaExpanded = 'true';
                } );
            } );
        }

        const toggleParent = element.dataset.toggleParent && element.dataset.toggleParent.length > 0;

        if ( toggleParent ) {
            const parent = element.closest( element.dataset.toggleParent as string );

            if ( isOpen && parent ) {
                parent.classList.remove( 'collapsing-child-open' );
            }

            if ( !isOpen && parent ) {
                parent.classList.add( 'collapsing-child-open' );
            }
        }

        if ( target ) {
            target.dispatchEvent( new CustomEvent( `collapse:${isOpen ? 'collapsed' : 'expand'}`, {
                bubbles: true,
                detail: {
                    trigger: element,
                },
            } ) );
        }
    }

    static selectors = {
        btn: '[data-toggle="collapse"]',
    };
}

export default CollapsingElements;
