import * as Sentry from '@sentry/browser';

const blacklistUserAgents = [
    /Seekport Crawler/,
    /TurnitinBot/,
    /MauiBot/,
    /MJ12bot/,
    /AhrefsBot/,
    /Yeti/,
    /HeadlessChrome/,
];

const denyUrls = [
    /(.*)\/gpt\/(.*)/,
    /ampproject/,
    /bat.bing.com/,
    /translatoruser-int/,
    /doubleclick.net/,
    /googleadservices.com/,
    /googlesyndication.com/,
    /hotjar.io/,
    /clarity.ms/,
    /onetrust.com/,
    /fuseplatform.net/,
];

const allowedHosts = [
    /^localhost$/,
    /^(www\.|beta\.|[a-zA-Z0-9-]*-dev\.)?thinkspain.com$/,
];

const blacklistErrorMessages = [
    /Failed to fetch/,
    /Type Error: Load failed/,
    /TypeError: Load failed/,
    /instantSearchSDKJS/,
];

export interface SentryError extends Error {
    sentryIgnored: boolean;
}

function filterByAllowedHosts( event: Sentry.Event, hint: Sentry.EventHint ): Sentry.Event | null {
    if ( !allowedHosts.some( ( host ) => host.test( window.location.hostname ) ) ) {
        return null;
    }

    return event;
}

function filterByFacebookParameter( event: Sentry.Event, hint: Sentry.EventHint ): Sentry.Event | null {
    if ( location.search.indexOf( 'fbclid' ) !== -1 ) {
        return null;
    }

    return event;
}

function filterByUserAgents( event: Sentry.Event, hint: Sentry.EventHint ): Sentry.Event | null {
    for ( let agentIndex = 0; agentIndex < blacklistUserAgents.length; agentIndex++ ) {
        if ( blacklistUserAgents[ agentIndex ].test( navigator.userAgent ) ) {
            return null;
        }
    }

    return event;
}

function filterByErrorMessage( event: Sentry.Event, hint: Sentry.EventHint ): Sentry.Event | null {
    if ( hint && hint.originalException ) {
        const originalErr = hint.originalException as SentryError;

        if ( originalErr.message ) {
            for ( let msgIndex = 0; msgIndex < blacklistErrorMessages.length; msgIndex++ ) {
                if ( blacklistErrorMessages[ msgIndex ].test( originalErr.message ) ) {
                    return null;
                }
            }
        }
    }

    return event;
}

function filterByIgnored( event: Sentry.Event, hint: Sentry.EventHint ): Sentry.Event | null {
    if ( hint && hint.originalException ) {
        const err = hint.originalException as SentryError;

        if ( err.sentryIgnored && err.sentryIgnored === true ) {
            return null;
        }
    }

    return event;
}

function filterBySampleRate( event: Sentry.Event, hint: Sentry.EventHint ): Sentry.Event | null {
    if ( hint && hint.originalException ) {
        const err = hint.originalException as Error;

        if ( err.message && /property enquiry/.test( err.message ) ) {
            return event;
        }
    }

    const sampleRate: number = parseFloat( process.env.SENTRY_SAMPLE_RATE ) || 0.3;

    if ( Math.random() > sampleRate ) {
        return null;
    }

    return event;
}

const config: Sentry.BrowserOptions = {
    dsn: window.sentryDSN,
    release: process.env.GIT_HASH,
    environment: window.environment,
    debug: process.env.SENTRY_DEBUG === 'true',
    denyUrls: denyUrls,
    sampleRate: 1,
};

export default function initSentry(): void {
    Sentry.init( config );

    Sentry.setTag( 'stack', 'frontend' );

    Sentry.addEventProcessor( filterBySampleRate );

    Sentry.addEventProcessor( filterByAllowedHosts );

    Sentry.addEventProcessor( filterByErrorMessage );

    Sentry.addEventProcessor( filterByFacebookParameter );

    Sentry.addEventProcessor( filterByUserAgents );

    Sentry.addEventProcessor( filterByIgnored );
}
